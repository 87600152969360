import { post } from '@/utils/request'
import { IExtendObj } from '@/types'
import useNotLogin from '@/hooks/useNotLogin'
const gate = '/api/mdm'

// 查询所有省市区数据（需要token）
export function getAllCity(data: object = {}) {
  return post<IExtendObj>(`${gate}/district_chn/v1/query_tree_list`, data)
}

// 查询所有省市区数据无需token
export function selectAllCity(data: object = {}) {
  return post<IExtendObj[]>(`${gate}/district_chn/v1/select_tree_list`, data)
}

// 根据城市code查找数据库中的城市id
export function queryCityByCodes(data: object) {
  return post<IExtendObj>(`${gate}/district_chn/v1/query_by_codes`, data)
}

export function queryBySystemConfigKeyCode(data: object = {}) {
  return post<IExtendObj>(`${gate}/system_config/v1/query_by_system_config_key_code`, data)
}

// 自动识别地址
export function getAddress(data: { expressInfo: string }) {
  return post<IExtendObj>(`${gate}/district_chn/v1/query_express_info`, data)
}

// 获取物流公司
export function getLogistics(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/logistics_company/v1/query_logistics_company_list`, data)
}

// 售后原因
export function getReasonList(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/after_sale_reason/v1/batch_after_sale_reason`, data)
}

//获取银行卡
export function getByParam(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/bank/v1/get_by_param`, data)
}

// 获取首页信息
export function queryHomePageRenovationInfo(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/platform/shopRenovation/queryHomePage`, data)
}

// 获取装修页面信息
export function queryPageRenovationInfo(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/platform/shopRenovation/info`, data)
}

// 获取商品组
export function queryGoodsGroup(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/platform/product_group/v1/query_product_group`, data)
}

// 获取商品组商品
export function queryGoodsGroupGoods(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/platform/product_group/v1/query_product_group_goods`, data)
}

// 获取商品组详情
export function queryGoodsGroupDetail(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/platform/product_group/v1/detail`, data)
}

// 查找品牌组
export function queryBrandGroupPage(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/mdm_brand_group/v1/select_brand_group_page`, data)
}

// 查找品牌组-详情
export function queryBrandGroupDetail(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/mdm_brand_group/v1/brand_group_detail`, data)
}

// 查找品牌组-指定品牌
export function selectBrandGroupBrandPage(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/mdm_brand_group_appoint/v1/select_brand_group_brand_page`, data)
}

// 用户端获取广告
export function queryUserAdvert(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/mdm_advert/v1/query_user_advert`, data)
}

// 用户端用户端广告保存弹出记录获取广告
export function saveRecordAdvert(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/mdm_advert/v1/save_record`, data)
}

// 查看用户店铺公告列表
export function queryUserAnnouncement(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/announcement/v1/query_user_announcement`, data)
}
// 查看用户店铺公告列表
export function queryUserAnnouncementPage(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/announcement/v1/query_user_announcement_page`, data)
}

// 店铺公告详情接口
export function queryByIdAnnouncement(data: IExtendObj) {
  return post<IExtendObj>(`${gate}/announcement/v1/get_by_id`, data)
}

export function rememberCustomerOperate(data: IExtendObj) {
  return useNotLogin(() => post<any>(`${gate}/customer/v1/remember_customer_operate`, data), {
    content: null
  })
}
// 店铺消息未读数量接口
export function announcementUnReadCnt(data: IExtendObj) {
  return post<IExtendObj>(`/api/mdm/announcement/v1/un_read_cnt`, data)
}
// 店铺公告读接口
export function announcementRead(data: IExtendObj) {
  return post<IExtendObj>('/api/mdm/announcement/v1/read', data)
}
