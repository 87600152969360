<template>
  <yf-dialog
    :visible="visible"
    :title="dialogTitle"
    width="800px"
    class="service-dialog"
    :not-show-close="false"
    :close-on-click-modal="true"
    :button-list="buttonList"
    :beforeClose="beforeClose"
    append-to-body
    @footerButtonClick="beforeClose"
  >
    <div v-html="detail" class="content"></div>
  </yf-dialog>
</template>
<script lang="ts" setup>
  import { ref } from 'vue'
  import { getContractContent } from '@/api/cus'

  const visible = ref<boolean>(false)
  const dialogTitle = ref<string>('平台服务协议')
  const buttonList = [
    {
      event: 'ok',
      label: '确定',
      type: 'primary'
    }
  ]
  const detail = ref<string>('')
  const getDetail = async (val: number) => {
    try {
      const { content } = await getContractContent(val)
      detail.value = content
    } catch (e) {
      detail.value = ''
    }
  }
  const open = async (val: 1 | 2 | 3) => {
    const titleMap = {
      1: '平台服务协议',
      2: '隐私政策',
      3: '平台注册协议'
    }
    dialogTitle.value = titleMap[val]
    visible.value = true
    await getDetail(val)
  }
  const beforeClose = () => {
    visible.value = false
  }
  defineExpose({
    open
  })
</script>
<style lang="scss" scoped>
  .service-dialog {
    :deep(.el-dialog__body) {
      max-height: 500px;
      overflow-y: auto;
    }
  }

  .content {
    height: 500px;
    overflow-y: auto;
  }
</style>
