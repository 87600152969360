<template>
  <div class="date-picker">
    <el-input class="ipt" style="position: fixed; bottom: -9999px" />
    <el-date-picker
      v-model="form.startTime"
      v-bind="$attrs"
      :type="props.type"
      :style="props.type === 'datetime' ? { width: '200px' } : {}"
      :disabled="disabled"
      :disabled-date="props.disabledDateMin"
      :placeholder="props.startPlaceholder"
      :value-format="$attrs.valueFormat || valueFormatCp"
      @change="handleTimeMin"
    />
    <span style="display: inline-block; margin: 0 4px; font-size: 12px">{{ rangeSeparator }}</span>
    <el-date-picker
      v-model="form.endTime"
      v-bind="$attrs"
      :type="type"
      :style="props.type === 'datetime' ? { width: '200px' } : {}"
      :disabled="disabled || disabledDateEnd || !form.startTime"
      :disabled-date="props.disabledDateMax ? props.disabledDateMax : disabledDateEndMax"
      :placeholder="props.endPlaceholder"
      :value-format="$attrs.valueFormat || valueFormatCp"
      @change="handleTimeMax"
    />
  </div>
</template>
<script lang="ts" setup>
  import moment from 'moment'
  import { ref, reactive, computed, watch } from 'vue'
  import type { IExtendObj } from '@/types'

  interface IYfDateProps {
    modelValue: (string | number)[]
    disabled?: boolean
    type:
      | 'year'
      | 'month'
      | 'date'
      | 'dates'
      | 'datetime'
      | 'week'
      | 'datetimerange'
      | 'daterange'
      | 'monthrange'
    disabledDateMin?: () => boolean
    disabledDateMax?: () => boolean
    disabledDateEnd?: boolean
    startPlaceholder?: string
    endPlaceholder?: string
    endTimeDefault?: number
    rangeSeparator?: string
    endMonth?: number
    endTimeMax?: number
  }
  /**
   * 自定义事件
   */
  interface IYfDateEmits {
    (e: 'update:modelValue', value: (string | number)[]): void
  }
  const props = withDefaults(defineProps<IYfDateProps>(), {
    type: 'date',
    startPlaceholder: '开始日期',
    endPlaceholder: '结束日期',
    endTimeDefault: 31 * 24 * 3600 * 1000,
    rangeSeparator: '至',
    endMonth: 1
  })
  const emit = defineEmits<IYfDateEmits>()
  const form = reactive({
    startTime: '',
    endTime: ''
  } as {
    startTime: string | number
    endTime: string | number
  })

  watch(
    () => props.modelValue,
    () => {
      console.log(props.modelValue)
      form.startTime = props.modelValue ? props.modelValue[0] : ''
      form.endTime = props.modelValue ? props.modelValue[1] : ''
    },
    { immediate: true }
  )
  const valueFormatCp = computed(() => {
    const map = {
      year: 'YYYY',
      month: 'YYYY-MM',
      date: 'YYYY-MM-DD HH:mm:ss',
      datetime: 'YYYY-MM-DD HH:mm:ss'
    }
    return map[props.type] || ''
  })
  const disabledDateEndMax = (time: { getTime: () => number }) => {
    const curDate = new Date(form.startTime).getTime() + 8.64e7
    const three = props.endTimeDefault
    const threeMonths = curDate + three
    return time.getTime() < new Date(form.startTime).getTime()
  }
  const handleTimeMin = () => {
    if (!form.startTime) {
      form.endTime = ''
      emit('update:modelValue', [])
      return
    }
    const time1 = new Date(
      new Date(form.startTime).getFullYear(),
      new Date(form.startTime).getMonth() + props.endMonth,
      new Date(form.startTime).getDate()
    ).getTime()
    const time2 = props.endTimeMax ? new Date(props.endTimeMax).getTime() : time1
    form.endTime = moment(Math.min(time1, time2)).format(
      props.type === 'date' ? 'YYYY-MM-DD' : 'YYYY-MM-DD HH:mm:ss'
    )
    emit('update:modelValue', [form.startTime, form.endTime])
  }
  const handleTimeMax = () => {
    if (!form.endTime) {
      form.startTime = ''
      emit('update:modelValue', [])
      return
    }
    emit('update:modelValue', [form.startTime, form.endTime])
  }
</script>
<style lang="scss" scoped>
  .date-picker {
    display: flex;
    align-items: center;
    width: 100%;
    ::v-deep {
      .el-date-editor.el-input {
        width: calc(100% - 50%);
      }
      .el-input__wrapper {
        width: 100%;
      }
    }
    .ipt {
      :deep(input) {
        -webkit-text-security: disc !important;
      }
    }
  }
</style>
